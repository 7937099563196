import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import HomeContainer from "../containers/HomeContainer"
import HeadData from "../data/HeadData"
import SkewedHeaderBg from "../headers/SkewedHeaderBg"
import PageDemoFullHeaderColorBg from "../headers/PageDemoFullHeaderColorBg"
import HeaderNav from "../navigation/HeaderNav"
import PageDemoHeroText from "../headers/PageDemoHeroText"

export default () => {
  const data = useStaticQuery(graphql`
    query OusourceMetrcQuery {
      imageHero: file(relativePath: { eq: "hero/outsource-metrc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const image = data.imageHero?.childImageSharp.fluid;

  return (
    <HomeContainer>
      <HeadData title="Outsource Metrc" />

      <SkewedHeaderBg />

      <PageDemoFullHeaderColorBg>
        <HeaderNav isHome />
        <PageDemoHeroText
          title="Outsource Metrc Reporting Today"
          jotFormId="222129164635454"
          image={image}
        >
          <div className="flex flex-col sm:w-full md:w-full w-3/4 font-body font-medium text-xl sm:text-lg text-grolens-green-800 mb-6 sm:mb-8 space-y-4">
            <span>
              Allow a metrc certified professional manage your compliance
              reporting for a fraction of the cost of managing in house.
            </span>
            <br />
            <span>How it works</span>
            <ul className="list-decimal list-outside pl-8">
              <li className="pb-2">Email or Text Facility Updates</li>
              <li className="pb-2">
                GroLens formats and Uploads Data in less than 48 hours
              </li>
              <li className="pb-2">You receive a notice of completion</li>
            </ul>
          </div>
        </PageDemoHeroText>
      </PageDemoFullHeaderColorBg>
    </HomeContainer>
  )
}
